import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Header from "../components/header"
import Navbar from "../components/navBar"
import Footer from "../components/footer"

const IndexPage = ({ location, pageInfo}) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      image1: file(relativePath: { eq: "ESA_MoonBase_1000x1000.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "artemis-deployd.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return (
  <Container fluid className="p-0 main">
    <Row noGutters className="justify-content-center">
      <Col>
        <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Header siteTitle={SEO.title} />
      </Col>
    </Row>
    <Navbar pageInfo={pageInfo} />
    <Row noGutters>
      <Col>
        <Container fluid className="marketing">

          <Row className="featurette ml-0 mb-0">
            <div className="col-md-7">
            <h2 className="featurette-heading">Our Vision</h2>
              <p className="featurette-subheading text-muted">The First Commerical Moon Base.</p>
              <p className="lead">We seek to help design and launch the first private Lunar Settlement by any means available, working with like-minded organizations across the Earth.</p>
              <p className="lead">The Lunar Resources Company and The Moon Society work together to accelerate the day that human beings are thriving on the Moon and participate in a flourishing cislunar industry.</p>
              <p className="lead"><Link to="/about">Read More...</Link></p>

              <p className="lead text-small"><i>Image Copyright &copy; ESA - P. Carril. Used with Permission.</i></p>
            </div>
            <div className="col-md-5 p-0">
              <Link to="/about"><Img fluid={data.image1.childImageSharp.fluid} alt="" /></Link>
            </div>
          </Row>

          <Row className="featurette ml-0 align-top">
            <div className="col-md-7 bg-secondary order-md-2">
            <h2 className="featurette-heading featurette-dark">The Artemis Project</h2>
              <p className="featurette-subheading featurette-dark">A Commerical Lunar Settlement plan, designed in 1994.</p>
              <p className="lead featurette-dark">Our organization was founded by the designers and advocates of a detailed plan to fund and launch the mission to estabish the first private sector moon base.</p>
              <p className="lead featurette-dark">Greg Bennett, a former NASA mission controller, wrote this article that originally appeared in Analog Magazine in January 1995.</p>
              <p className="lead featurette-dark"><Link to="/news/1995/01/artemis-project-selling-the-moon">Read More...</Link></p>
            </div>
            <div className="col-md-5 ml-0 pl-0 order-md-1">
              <Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image2.childImageSharp.fluid} alt="" /></Link>
            </div>
          </Row>
        </Container>
    
      </Col>
    </Row>
    <Row noGutters>
      <Col className="footer-col">
        <Footer />
      </Col>
    </Row>
  </Container>
)}

export default IndexPage



